label {
	display: block;
	margin-top: 10px;
}
/* --------------Loading-------------------------------- */
.centered {
	top: 50%;
	left: 50%;
	position: absolute;
}

.centered .loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #f9bb23; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/* --------------Login---------------------------------- */
.login {
	background: url("../media/images/body_catalog_bg.jpg");
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login .card-container.card {
	max-width: 350px !important;
	padding: 40px 40px;
}

.card {
	background-color: rgba(255, 255, 255, 0.2);
	padding: 20px 25px 30px;
	margin-left: auto;
	margin-right: auto;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 10px;
	-moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
	width: 96px;
	height: 96px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	border: 1px solid rgba(0, 0, 0, 0.3);
}
/* ----------------Home----------------------------------------- */

.home {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 200px 0;
}

.home h1,
.home p {
	color: #fff;
	font-weight: 600;
	margin: 10px 0 10px;
}

/* ----------------Navbar--------------------------------------- */

.sub_video {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	min-width: 100vw;
	min-height: 100vh;
	z-index: -1;
}

.overlay {
	position: fixed;
	z-index: -1;
	background: rgba(20, 20, 20, 0.6);
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.main nav {
	position: relative;
	z-index: 3;
	padding: 0;
}

.logo {
	display: inline-block;
	margin-left: 40px;
	margin-right: 40px;
}

.logo img {
	height: 60px;
}

.main nav .menu-button {
	z-index: 1000;
	position: relative;
	top: 0;
	left: 0;
	background: #f9bb23;
	transition: all 0.5s ease;
}

.main nav .menu-button.hide {
	display: none;
}

.main nav .menu-button:hover {
	background: #3d8ccb;
}

.main nav .menu-button img {
	height: 60px;
	width: 60px;
	margin: 10px;
}

.main .close-button {
	position: fixed;
	height: 80px;
	width: 80px;
	display: none;
	z-index: 100;
	top: 0;
	left: 0;
	background: #f9bb23;
	transition: all 0.5s ease;
}

.main .close-button.show {
	display: block;
}

.main .close-button:hover {
	background: #3d8ccb;
}
.main .close-button img {
	width: 60px;
	height: 60px;
	margin: 10px;
}

/* ----------------Overlay menu------------------------- */

.overlay-menu {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 4;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.9);
	overflow-x: hidden;
	transition: all 0.5s ease;
}

.overlay-menu .menu .item:hover {
	border: 3px solid #f9bb23;
}

.overlay-menu .menu .item {
	position: relative;
	box-sizing: border-box;
	overflow: hidden;
	height: 50vh;
	width: 50vw;
}

.overlay-menu .menu .item .background {
	position: absolute;
	top: -30px;
	left: -30px;
	right: -30px;
	bottom: -30px;
	transition: all 0.5s ease;
}

.overlay-menu .menu .item:hover .background {
	-webkit-filter: blur(8px);
	-moz-filter: blur(8px);
	-o-filter: blur(8px);
	-ms-filter: blur(8px);
	filter: blur(8px);
}

.overlay-menu .menu .item.services .background {
	background: url("../media/images/services.jpg");
	background-size: cover;
}
.overlay-menu .menu .item.about .background {
	background: url("../media/images/about.jpg") no-repeat center center;
	background-size: cover;
}
.overlay-menu .menu .item.cisterns .background {
	background: url("../media/images/cisterns.jpg") no-repeat center center;
	background-size: cover;
}
.overlay-menu .menu .item.group .background {
	background: url("../media/images/group.jpg") no-repeat center center;
	background-size: cover;
}
.overlay-menu .menu .item.news .background {
	background: url("../media/images/news.jpg") no-repeat center center;
	background-size: cover;
}
.overlay-menu .menu .item.contacts .background {
	background: url("../media/images/contacts.jpg") no-repeat center center;
	background-size: cover;
}

.overlay-menu .menu .item h1 {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80%;
	transform: translate(-50%, -50%);
	color: #fff;
	text-align: center;
	margin: 0;
	padding: 0;
	line-height: 1;
	font-weight: 700;
	font-size: calc(1.2vh + 1.2vw);
}

.overlay-menu .one-link {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

/* -------------------------------- */
.top-menu {
	display: inline-block;
	position: relative;
}

.top-menu > ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.top-menu > ul > li {
	display: table-cell;
	height: 80px;
	vertical-align: middle;
}

.top-menu .phone a {
	color: #fff;
	font-size: 28px;
	line-height: 1;
	text-decoration: none;
	transition: all 0.5s ease;
	font-weight: 700;
}

.top-menu .phone a:hover,
.top-menu .phone a:focus {
	color: #f9bb23;
}

.top-menu .phone a strong {
	font-weight: 700;
}

.top-menu .message button {
	padding: 9px 20px 11px;
	background: #f9bb23;
	color: #fff;
	border-radius: 24px;
	font-size: 22px;
	line-height: 1;
	font-weight: 700;
	text-decoration: none;
	margin: 0 10px 0 20px;
	border: none;
}

.top-menu .message button:hover {
	background: #3d8ccb;
	transition: all 0.5s ease;
}

.top-menu .language {
	position: relative;
	padding-left: 10px;
}

.top-menu .language img {
	height: 48px;
	width: 48px;
	margin: -4px 10px 0 0;
}

.top-menu .language ul {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s, visibility 0s 0.5s;
	margin: 0;
	padding: 0;
	list-style: none;
	top: 73px;
	z-index: 100;
}

.top-menu .language ul li {
	margin-bottom: 5px;
}

.contact-us-modal .modal-content .modal-body input:focus,
.contact-us-modal .modal-content .modal-body textarea:focus {
	box-shadow: none;
}

.top-menu .language:hover ul {
	opacity: 1;
	visibility: visible;
	transition: opacity 0.5s;
}

.top-menu .language ul li img {
	height: 48px;
	width: 48px;
	transition: all 0.5s ease;
	border: 3px solid transparent;
	border-radius: 50%;
}
.top-menu .language ul li:hover img {
	border: 3px solid #f9bb23;
	border-radius: 50%;
}

/* ------------Content-------------------------- */
.content {
	background: #fff;
	padding: 30px;
	opacity: 0.9;
	border-radius: 10px;
}

.content .content-header {
	margin-bottom: 30px;
}

.content .content-header h1 {
	margin: 0;
	padding: 0;
	line-height: 1;
	font-size: 32px;
	font-weight: 700;
	color: #f9bb23;
	text-transform: uppercase;
	transition: all 0.5s ease;
	display: inline;
}

.content .content-header a:hover {
	text-decoration: none;
}

.content .content-header a:hover h1 {
	color: #3d8ccb;
	text-decoration: none;
}

/* ------------Service Page--------------------- */
.service-page {
	padding: 5px;
}

.service-page .head {
	text-align: center;
	margin-bottom: 40px;
}

.service-page .head p {
	margin-top: 25px;
	color: #555;
	font-size: 18px;
	text-align: justify;
	text-indent: 1.5em;
}

.service-page .head h1 {
	color: #f9bb23;
	font-weight: 700;
}

.service-page .item {
	text-align: center;
	height: 265px;
	width: 30%;
	position: relative;
	transition: all 0.5s ease;
	border: 3px solid transparent;
}

.service-page .item:hover {
	transform: scale3d(1.1, 1.1, 2);
	transition: 1.2s;
}

.service-page .item img {
	max-width: 35%;
}

.service-page .item p {
	color: #555;
	font-size: 18px;
	width: 90%;
	position: absolute;
	top: 130px;
	left: 50%;
	transform: translateX(-50%);
	line-height: 1.1;
	margin: 0;
	padding: 0;
}
/* -----------News-------------------------- */
.news-page {
	align-items: center;
}

.news-page .card {
	padding: 0;
	margin-top: 0px !important;
	margin: 0 auto 10px !important;
	border-radius: 0px;
	position: relative;
	box-shadow: 0px 0px 0px rgb(0 0 0 / 20%);
	border-color: transparent !important;
	background-color: transparent !important;
}

.news-page a {
	color: #555 !important;
	text-decoration: none;
}

.news-page .card img {
	height: 20vh;
	width: 100%;
	background-size: cover;
}

.news-page .col .card:hover {
	transform: scale(1.02);
}

.news-page .card-body:hover {
	color: #000 !important;
}

.news-page .card-body {
	padding: 2px;
	line-height: 1;
}

.news-page .card-body p.card-text {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.news-post .header-news {
	margin: 0 0 20px;
	text-align: left;
}

.news-post .header-news h2 {
	line-height: 1;
	font-size: 28px;
	font-weight: 700;
	padding: 0;
	color: #f9bb23;
}

.news-page .card .footer .text-muted {
	padding-left: 2px;
}

.news-post .date {
	line-height: 1;
	margin: 0 0 20px;
	padding: 0;
	color: #555;
}

.news-post .text {
	margin-bottom: 15px;
	text-align: justify;
	text-justify: inter-word;
	color: #555;
}

.news-post .text p {
	font-size: 16px;
}

.news-post .attachments > div {
	margin: 20px 20px;
	color: #555;
}

.news-post .attachments .attachment a > svg {
	height: 5vh;
	width: 100%;
}

.news-post .attachments .attachment a:hover > svg {
	transform: scale3d(1.1, 1.1, 2);
	transition: 1s;
}

.news-post .attachments .attachment .item {
	text-align: center;
	margin: 10px 0 10px 0;
}

/* ----------------Contact Page------------------ */
.contacts-page .text {
	height: auto;
	padding: 10px;
}

.contacts-page .text h1 {
	color: #f9bb23;
	font-weight: 700;
	font-size: 28px;
	line-height: 1;
	text-align: center;
	margin: 0 0 40px;
	padding: 0;
}

.contacts-page .text .address {
	box-sizing: border-box;
}

.contacts-page .text div {
	color: #555;
	font-weight: 400;
	display: block;
	margin: 20px 5px 20px 5px;
}

.contacts-page .text div span {
	color: #555;
	font-size: 15px;
}

.contacts-page .text div a {
	color: #555;
	font-size: 15px;
}

.contacts-page .text svg {
	float: left;
	margin-right: 15px;
	color: #106eea;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	padding: 8px;
	border: 2px dotted #b3d1fa;
}
/* ---------------Company---------------------- */
.company-group .description {
	text-align: center;
	margin-bottom: 25px;
}

.company-group .description p {
	font-size: 18px;
	text-indent: 1.5em;
	text-align: justify;
	color: #555;
}

.company-group .row {
	margin: 0;
}

.company-group .row > div {
	padding: 15px 25px;
}

.company-group .item {
	border-radius: 20px;
	height: 200px;
	position: relative;
	padding: 0;
	box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.5);
	transition: all 0.5s ease;
	background-size: cover;
}

.company-group .item:hover {
	transform: scale(1.05);
	box-shadow: 0 0 20px 0 rgba(50, 50, 50, 0.4);
}

.company-group .item .group-overlay {
	position: absolute;
	left: 10%;
	right: 10%;
	top: 20px;
	bottom: 20px;
	background: rgba(250, 250, 250, 0.7);
	border-radius: 20px;
	transition: all 0.5s ease;
}

.company-group .item:hover .group-overlay {
	background: rgba(250, 250, 250, 0.9);
}

.company-group .item .logotype {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 135px;
	max-width: 70%;
}

.company-group .item a {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 30px;
}

.group-item .row {
	justify-content: space-evenly;
	flex-wrap: wrap;
	text-align: justify;
}

.group-item .logo img {
	width: 100%;
	margin: 5px 0 30px;
	height: inherit;
}

.group-item .description img {
	max-width: 100%;
}

.group-item .description p {
	font-size: 18px;
	font-weight: 300;
}

.group-item .description strong {
	font-weight: 700;
	color: #3d8ccb;
}

.group-item .description ul {
	font-size: 18px;
	font-weight: 300;
}

/* -----------About Company-------------------- */
.about-page {
	margin: 30px 0 30px 0;
}

.about-page .about-menu {
	margin: 0 0 30px;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: space-around;
}

.about-page .about-menu .nav-item .nav-link {
	border: none;
	border-radius: 10px;
	color: #fff;
	font-weight: 700;
	padding: 16px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
	transition: all 0.3s ease 0s;
	outline: none;
}

.about-page .about-menu .nav-item .nav-link {
	background: #f9bb23;
}

.about-page .about-menu .nav-item .nav-link:hover {
	background: #3d8ccb;
	text-decoration: none;
	color: #fff;
}

.about-page .about-company {
	padding: 30px 30px 5px 30px;
	text-align: justify;
	color: #555;
	text-indent: 1.5rem;
}

.about-page .partners {
	margin: 30px 0 30px 0;
}

.about-page .partners .row div {
	height: 180px;
	position: relative;
}

.about-page .partners .row div img {
	position: absolute;
	max-width: 80%;
	max-height: 220px;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.about-page .awards {
	margin: 30px 0 30px 0;
}

.about-page .awards .row {
	border-bottom: 1px solid #ccc;
}

.about-page .awards .row:last-child {
	border-bottom: transparent;
}

.about-page .awards .row > div:first-child {
	height: 180px;
	position: relative;
}

.about-page .awards .row > div:last-child {
	padding: 15px;
	color: #555;
}

.about-page .awards img {
	position: absolute;
	max-width: 80%;
	max-height: 160px;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
/* -----------Project Page--------------------- */

.stock {
	padding: 20px;
	display: flex;
	flex-direction: column;
}

.stock .stock-header {
	margin-bottom: 10px;
	text-align: justify;
	text-indent: 1.5em;
	color: #555;
	font-size: 18px;
}

.stock .row {
	margin-left: 0px;
	margin-right: 0px;
}

.stock .row .contain .card {
	padding: 0;
	margin: 2px;
	margin-top: 0px;
	box-shadow: 0px 2px 2px rgb(0 0 0 / 30%);
	/* border-radius: 15px; */
}

.stock .contain {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	/* max-width: 1100px; */
}

.stock .row .contain .card {
	position: relative;
	width: 100%;
	height: 21vh;
	overflow: hidden;
}
.stock .contain .card .imgBx,
.stock .contain .card .contentBx {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.stock .contain .card .imgBx img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: fill;
}

.stock .contain .card .contentBx::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	transform: scaleX(0);
	transition: transform 0.5s ease-in-out;
	transform-origin: right;
	transition-delay: 0.5s;
}

.stock .contain .card:hover .contentBx::before {
	transform: scaleX(1);
	transition: transform 0.5s ease-in-out;
	transform-origin: left;
	transition-delay: 0s;
}

.stock .contain .card .contentBx {
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgb(255, 255, 255);
}

.stock .contain .card .contentBx .cont {
	position: relative;
	padding: 30px;
	z-index: 1;
	transition: 0.5s;
	transform: translateX(-300px);
	transition-delay: 0s;
}

.stock .contain .card:hover .contentBx .cont {
	transform: translateX(0px);
	transition-delay: 0.5s;
}

.stock .contain .card:hover .contentBx .cont h5 {
	font-size: 1.3em;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.stock .contain .card:hover .contentBx .cont p {
	font-size: 1em;
	line-height: 1.4em;
	font-weight: 300;
}
/* -----------Boilerplate---------------------- */
.content {
	background: #fff;
	padding: 30px;
	opacity: 0.9;
	border-radius: 10px;
}

.content .content-header {
	margin-bottom: 30px;
	text-align: center;
}

.content .content-header-child {
	margin-bottom: 30px;
}

.content .content-header-child a {
	color: #f9bb23;
	text-decoration: none;
}

.content .content-header-child a:hover {
	color: #3d8ccb;
}

/* --------Carousel----------------------- */
.slideshow {
	margin: 0;
	overflow: hidden;
	position: relative;
	max-width: 80px;
}

.slideshow .slideshowSlider {
	white-space: nowrap;
	transition: 1s ease;
}

.slideshow .slideshowSlider .slide {
	display: inline-block;
	height: auto;
	width: 100%;
}

.slideshow .slideshowSlider .slide .title {
	color: #fff;
	font-size: 28px;
	line-height: 1;
	text-decoration: none;
	transition: all 0.5s ease;
	font-weight: 700;
}

.slideshow .slideshowSlider .slide .description {
	color: #fff;
	font-size: 26px;
	line-height: 1;
	text-decoration: none;
	transition: all 0.5s ease;
	font-weight: 500;
}

/* --------CarouselOil----------------------- */
.slideshowOil {
	margin: 0 0 0 20px;
	overflow: hidden;
	position: relative;
	max-width: 200px;
}

.slideshowOil .slideshowSliderOil {
	white-space: nowrap;
	transition: 1s ease;
}

.slideshowOil .slideshowSliderOil .slide_oil {
	display: inline-block;
	height: auto;
	width: 100%;
}

.slideshowOil .slideshowSliderOil .slide_oil .title {
	color: #fff;
	font-size: 28px;
	line-height: 1;
	text-decoration: none;
	transition: all 0.5s ease;
	font-weight: 700;
}

.slideshowOil .slideshowSliderOil .slide_oil .price {
	color: #fff;
	font-size: 26px;
	line-height: 1;
	text-decoration: none;
	transition: all 0.5s ease;
	font-weight: 500;
}

/* --------Mobile--------------------- */
@media (max-width: 480px) {
	.navbar .logo {
		margin-left: 10px;
	}

	.navbar .logo img {
		height: 45px;
	}

	.top-menu ul .phone {
		display: none;
	}

	.top-menu ul .message {
		display: none;
	}

	.overlay-menu .menu .item h1 {
		width: 90%;
		font-size: 20px;
	}

	.overlay-menu .menu .item {
		height: 33.33vh;
		width: 50%;
	}

	.stock .contain .card {
		height: 18vh;
	}

	.stock .card {
		margin: 10px 10px;
	}

	.stock .contain .card:hover .contentBx .cont h5 {
		font-size: 0.7em;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	.stock .contain .card:hover .contentBx .cont p {
		font-size: 0.8em;
		line-height: 1em;
	}

	.about-page .about-menu {
		margin: 0 0 30px;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		justify-content: space-around;
	}

	.about-page .about-menu .nav-item .nav-link {
		font-weight: 300;
		padding: 10px 2px 10px 2px;
	}

	.about-page .about-company {
		padding: 0;
	}

	.contacts-page .text h4 {
		font-size: 1.2rem;
	}

	.contacts-page .text div span {
		font-size: 13px;
	}

	.contacts-page .text div a {
		font-size: 13px;
	}

	.slideshow {
		display: none;
	}

	.slideshowOil {
		display: none;
	}
}

@media (min-width: 481px) and (max-width: 767px) {
	.navbar .logo {
		margin-left: 10px;
	}

	.navbar .logo img {
		height: 45px;
	}

	.top-menu ul .phone {
		display: none;
	}

	/* .top-menu ul .message {
		display: none;
	} */

	.slideshow {
		display: none;
	}

	.slideshowOil {
		display: none;
	}

	.overlay-menu .menu .item h1 {
		width: 90%;
		font-size: 28px;
	}

	.stock .contain .card {
		height: 20vh;
	}

	.stock .card {
		margin: 10px 10px;
	}

	.stock .contain .card:hover .contentBx .cont h5 {
		font-size: 0.7em;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	.stock .contain .card:hover .contentBx .cont p {
		font-size: 0.8em;
		line-height: 1em;
	}

	.contacts-page .text h4 {
		font-size: 1.2rem;
	}

	.contacts-page .text div span {
		font-size: 13px;
	}

	.contacts-page .text div a {
		font-size: 13px;
	}
}

@media (min-width: 768px) and (max-width: 800px) {
	.navbar .logo {
		margin-left: 10px;
		margin-right: 20px;
	}

	.navbar .logo img {
		height: 45px;
	}

	.top-menu ul .phone {
		display: none;
	}

	/* .top-menu ul .message {
		display: none;
	} */

	.slideshow {
		display: none;
	}

	.slideshowOil .slideshowSliderOil .slide_oil .title {
		font-size: 20px;
		font-weight: 700;
	}

	.stock .contain .card {
		height: 18vh;
	}

	.stock .card {
		margin: 10px 10px;
	}

	.stock .contain .card:hover .contentBx .cont h5 {
		font-size: 0.7em;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	.stock .contain .card:hover .contentBx .cont p {
		font-size: 0.8em;
		line-height: 1em;
	}

	.contacts-page .text h4 {
		font-size: 1.2rem;
	}

	.contacts-page .text div span {
		font-size: 13px;
	}

	.contacts-page .text div a {
		font-size: 13px;
	}
}

@media (min-width: 970px) and (max-width: 1070px) {
	.top-menu ul .phone {
		display: none;
	}

	.stock .contain .card {
		height: 15vh;
	}
	.stock .contain .card:hover .contentBx .cont h5 {
		font-size: 0.7em;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	.stock .contain .card:hover .contentBx .cont p {
		font-size: 0.8em;
		line-height: 1em;
	}

	.contacts-page .text h4 {
		font-size: 1.2rem;
	}

	.contacts-page .text div span {
		font-size: 13px;
	}

	.contacts-page .text div a {
		font-size: 13px;
	}
}
