.wrapped {
	position: relative;
	width: 100%;
}

.wrapped .sidebar {
	position: fixed;
	background: #202020ff;
	height: 100%;
	width: 300px;
	overflow: hidden;
	transition: 0.5s;
}
.wrapped .sidebar.active {
	width: 60px;
}

.wrapped .sidebar ul {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
}

.wrapped .sidebar ul .logo {
	position: relative;
	display: block;
	padding-top: 10px;
	margin-bottom: 15px;
}

.wrapped .sidebar ul .logo.active {
	display: none;
}

.wrapped .sidebar ul .logo img {
	height: 45px;
}

.wrapped .sidebar ul li {
	list-style: none;
	position: relative;
	width: 100%;
	border-bottom: 1px solid #bdb8d7;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.wrapped .sidebar ul li a {
	position: relative;
	display: flex;
	color: #818181;
	width: 100%;
	text-decoration: none;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
}

.wrapped .sidebar ul li a .icon {
	position: relative;
	display: block;
	min-width: 60px;
	line-height: 60px;
	text-align: center;
}

.wrapped .sidebar ul li a .icon svg {
	color: #fff;
	font-size: 24px;
}

.wrapped .sidebar ul li a .title {
	position: relative;
	display: block;
	padding: 0 10px;
	height: 60px;
	line-height: 60px;
	white-space: nowrap;
}

.wrapped .sidebar ul li:hover {
	background: #4caf50;
}

.wrapped .sidebar ul li:hover .title {
	color: #fff;
}

.wrapped .sidebar ul li:nth-child(1) {
	margin-bottom: 20px;
}

.wrapped .sidebar ul li:nth-child(1):hover {
	background: transparent;
}

.wrapped .main_content {
	position: absolute;
	width: calc(100% - 300px);
	left: 300px;
	min-height: 10vh;
	background-color: #ffffff;
	transition: 0.5s;
}

.wrapped .main_content.active {
	width: calc(100% - 60px);
	left: 60px;
}

.wrapped .main_content .topbar {
	width: 100%;
	background: #fff;
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 2px 2px rgb(0 0 0 / 30%);
}

.wrapped .main_content .topbar .toggle {
	position: relative;
	width: 60px;
	height: 60px;
	cursor: pointer;
}

.wrapped .main_content .topbar .toggle::before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f0c9";
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 24px;
	line-height: 60px;
	text-align: center;
	color: #111;
}

.wrapped .main_content .logout {
	margin-right: 10px;
}

.main_content .content_admin {
	margin: 0 10px 10px 10px;
}
/* ---------Common----------------- */
.card-footer {
	background-color: transparent;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: center;
}

.card-footer button {
	margin: 0 5px 0 5px;
}

/* ----------Profile--------------------- */
.card .jumbotron {
	background-color: transparent;
	margin-bottom: 0;
	padding: 0;
	margin-bottom: 10px;
}

/* -------------Service-------------------- */
.main_content .content_admin .card {
	margin-top: 10px;
	background: #fff;
}

.card .card-body .btn_row {
	margin: 5px 0 5px 0;
}

.card .card-header {
	padding: 5px;
	background-color: transparent;
}

.card .card-body .btn_row button {
	margin: 0px 5px 0 5px;
}

.table tbody tr td img {
	height: 45px;
	width: 45px;
}

.table tbody tr td span {
	margin-right: 5px;
}
/* ---------News-------------------- */
.news_add .form-check > label {
	margin-top: 0px;
}

/* ------------myModal---------------------- */

.modal-content .form-group {
	text-align: left !important;
}

.modal-content .form-group label {
	margin-bottom: 1px;
}

.modal-content .form-group input::placeholder {
	/* Chrome/Opera/Safari */
	color: #bbb;
}
.modal-content .form-group input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #bbb;
}
.modal-content .form-group input::-moz-placeholder {
	/* Firefox 19+ */
	color: #bbb;
}
.modal-content .form-group input:-ms-input-placeholder {
	/* IE 10+ */
	color: #bbb;
}
.modal-content .form-group input:-moz-placeholder {
	/* Firefox 18- */
	color: #bbb;
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}
